@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap);
* {
  box-sizing: border-box; }

html, body {
  margin: 0 auto; }

body {
  background-color: #f6f7fc;
  color: #32325d;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  font-size: 16px; }

.container {
  margin: 0 auto;
  padding: 5px 15px;
  width: 75%; }

#intro {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  padding: 15px;
  margin: 0 auto 15px; }
  #intro .intro-copy {
    text-align: left;
    padding: 0 10%; }
    #intro .intro-copy p {
      margin: 9px 0;
      line-height: 1.5rem; }

.grid {
  width: 100%;
  max-width: 100%;
  padding: 0 10px;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: minmax(250px, auto);
  grid-auto-flow: dense; }
  .grid .medium {
    grid-column: span 2; }
  .grid .large {
    grid-column: span 2;
    grid-row: span 2; }
  .grid .tall {
    grid-row: span 2; }

.card {
  background-color: #fff;
  border-radius: 5px;
  min-height: 100px;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15) !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 4px solid rgba(50, 50, 93, 0.25);
  -webkit-background-clip: padding-box;
  /* for Safari */
  background-clip: padding-box;
  /* for IE9+, Firefox 4+, Opera, Chrome */ }
  .card .title {
    margin: 6px 0;
    font-size: 1.3rem; }
  .card p {
    margin: 8px auto;
    padding: 0 10%;
    text-align: left;
    font-size: 0.9rem; }
  .card .feature {
    width: 100%; }
  .card img {
    max-width: 100%;
    width: 100%; }
  .card .links {
    display: flex;
    justify-content: space-around;
    align-self: normal;
    padding: 5px;
    margin: 10px 0 0;
    background-color: #686be3; }
    .card .links a {
      color: #fff;
      text-decoration: none;
      display: inline-block; }
      .card .links a svg {
        vertical-align: text-top; }
      .card .links a:hover {
        color: #000; }

#footer {
  clear: both;
  margin-top: 40px;
  background-color: #000f08;
  width: 100%;
  height: 50px;
  min-height: 50px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-around; }

@media (max-width: 768px) {
  h1 {
    font-size: 1.4rem; }
  .container {
    margin: 0;
    padding: 0;
    width: 100%; }
  .medium, .large, .tall {
    grid-column: span 1 !important;
    grid-row: span 1 !important; } }

